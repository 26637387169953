import React from "react";
import Slider from "react-slick";

function Custom1() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <div className="relative">
            <img
              src="/assets/kids/3.jpg"
              className="w-full rounded-[18px] object-cover"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Stalls/Scarfs
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="/assets/kids/3eb4abd9cf74a5b704d3ce1cf5415a27.jpg"
              className="w-full rounded-[18px] object-cover"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Dresses/Maxi/Gowns
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="assets/kids/4.jpg"
              className="w-full rounded-[18px] object-cover"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Kurti/Tunic
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="assets/kids/5.jpg"
              className="w-full rounded-[18px] object-cover"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Stalls/Scarfs
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="assets/kids/6.jpg"
              className="w-full rounded-[18px] object-cover"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Dresses/Maxi/Gowns
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="assets/kids/6e6c9c6aa7c1b68b2b24c2185e204e32.jpg"
              className="w-full rounded-[18px] object-cover"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Stalls/Scarfs
              </p> */}
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
}

export default Custom1;
