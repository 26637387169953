import React from "react";
import Slider from "react-slick";

function Custom2() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 1000,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true, 
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <div className="relative">
            <img
              src="/assets/men/-8.jpg"
              className="w-full object-cover rounded-[18px]"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Pants/Plazzo
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="/assets/men/-9.jpg"
              className="w-full object-cover rounded-[18px]"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Dresses/Maxi/Gowns
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="/assets/men/-10.jpg"
              className="w-full object-cover rounded-[18px]"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Stalls/Scarfs
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="/assets/men/11.jpg"
              className="w-full object-cover rounded-[18px]"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Pants/Plazzo
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="/assets/men/-8.jpg"
              className="w-full object-cover rounded-[18px]"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Dresses/Maxi/Gowns
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="/assets/men/-9.jpg"
              className="w-full object-cover rounded-[18px]"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Stalls/Scarfs
              </p> */}
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
}

export default Custom2;
