import React, { useState } from "react";
import "./header.css";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const path = useLocation();
  const [dropdown, setDropdown] = useState(false);
  function handleDropdown() {
    setDropdown(!dropdown);
  }

  return (
    <>
      <div
        style={{ boxhadow: "0px 0px 4px 0px #00000040" }}
        className="flex w-full justify-between z-50 fixed top-0 items-center sm:px-[95px] px-[14px] bg-[#FFFFFFD6] py-[22px]"
      >
        <div
          className="overlay"
          onClick={handleDropdown}
          style={dropdown ? { right: 0 } : { right: "100%" }}
        ></div>
        <ul className="sm:flex hidden flex-col sm:flex-row sm:items-center items-start gap-[14px] sm:gap-[100px]">
          <li>
            <Link
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className={`text-[16px] uppercase transition-all duration-300 leading-[26px] ${
                path?.pathname === "/"
                  ? "font-[600] text-[#212121]"
                  : "font-[600] text-[#212121] hover:text-[#212121]"
              }`}
              to="/"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className={`text-[16px] uppercase transition-all duration-300 leading-[26px] ${
                path?.pathname === "/our-process"
                  ? "font-[600] text-[#212121]"
                  : "font-[600] text-[#212121] hover:text-[#212121]"
              }`}
              to="/our-process"
            >
              OUR PROCESS
            </Link>
          </li>
        </ul>
        <Link to="/">
          <div className="flex items-center gap-[4px]">
            <img src="assets/logo.svg" alt="" />
            <img src="assets/logo2.svg" alt="" />
          </div>
        </Link>
        <button onClick={handleDropdown} className="sm:hidden block">
          <img
            src="/assets/ham.png"
            className="h-[30px] object-cover w-[30px]"
            alt=""
          />
        </button>
        <div
          className={`flex sm:relative navbar-item fixed sm:w-auto w-[250px] sm:bg-transparent bg-[#FFFFFF] sm:flex-row sm:items-center items-start sm:pl-0 pl-[20px] sm:pt-0 pt-[30px] flex-col sm:gap-[30px] gap-[20px] top-0 bottom-0 ${
            dropdown ? "right-0" : "sm:right-0 right-[-250px]"
          }`}
        >
          <ul className="flex flex-col sm:flex-row sm:items-center items-start gap-[14px] sm:gap-[100px]">
            <li className="sm:hidden block">
              <Link
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className={`text-[16px] uppercase transition-all duration-300 leading-[26px] ${
                  path?.pathname === "/"
                    ? "font-[600] text-[#212121]"
                    : "font-[600] text-[#212121] hover:text-[#212121]"
                }`}
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="sm:hidden block">
              <Link
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className={`text-[16px] uppercase transition-all duration-300 leading-[26px] ${
                  path?.pathname === "/our-process"
                    ? "font-[600] text-[#212121]"
                    : "font-[600] text-[#212121] hover:text-[#212121]"
                }`}
                to="/our-process"
              >
                OUR PROCESS
              </Link>
            </li>
            <li>
              <Link
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className={`text-[16px] uppercase transition-all duration-300 leading-[26px] ${
                  path?.pathname === "/our-stroy"
                    ? "font-[600] text-[#212121]"
                    : "font-[600] text-[#212121] hover:text-[#212121]"
                }`}
                to="/our-stroy"
              >
                OUR STORY
              </Link>
            </li>
            <li>
              <Link
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className={`text-[16px] uppercase transition-all duration-300 leading-[26px] ${
                  path?.pathname === "/our-portfolio"
                    ? "font-[600] text-[#212121]"
                    : "font-[600] text-[#212121] hover:text-[#212121]"
                }`}
                to="/our-portfolio"
              >
                PORTFOLIO
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
