import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import OurProcess from "./pages/OurProcess";
import OurPortfolio from "./pages/OurPortfolio";
import OurStory from "./pages/OurStory";


function App() {
  return (
    <>
      <React.Suspense fallback={<>Loading...</>}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-process" element={<OurProcess />} />
            <Route path="/our-portfolio" element={<OurPortfolio />} />
            <Route path="/our-stroy" element={<OurStory />} />
          </Routes>
        </Router>
      </React.Suspense>
    </>
  );
}

export default App;
