import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Aos from "aos";

function OurPortfolio() {
  const [selected, setSelected] = useState("All");

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      <Header />
      <div className="pt-[128px] pb-[92px] sm:px-0 px-[20px]">
        <h1
          data-aos="fade-up"
          className="font-[700] pb-[8px] text-center text-[48px] leading-[63.84px] text-[#525252]"
          style={{ fontFamily: "Segoe UI, sans-serif" }}
        >
          Our Portfolio
        </h1>
        <p
          data-aos="fade-right"
          className="font-[400] text-[20px] leading-[26px] text-center text-[#000000] max-w-[728px] w-full m-auto"
          style={{ fontFamily: "Segoe UI, sans-serif" }}
        >
          Experience our craftsmanship... From our Inspirations to Moodboards,
          From Designs to Sketches, From Patterns to Khakhas, From Zardozis to
          Zari, From Resham strings to Gota Patti, From Swatches to Samples,
          From Modern Drapes to Traditional Kalidars, From Bridal Lehengas to
          Cocktail Gowns
        </p>
      </div>
      <div style={{zIndex: "10"}} className="bg-[#000000] sticky top-[96px] flex sm:flex-row flex-col sm:px-[91px] px-[20px] sm:py-0 py-[20px] sm:gap-[30px] gap-[14px] overflow-hidden">
        <button
          onClick={() => setSelected("All")}
          className={`font-[DM Sans] font-[400] text-[20px]  leading-[26.04px] text-[#FFFFFF] py-[16px] px-[40px] ${
            selected === "All" ? "bg-[#7A29FF]" : "bg-transparent"
          }`}
        >
          All
        </button>
        <button
          onClick={() => setSelected("EMBROIDERY")}
          className={`font-[DM Sans] font-[400] text-[20px]  leading-[26.04px] text-[#FFFFFF] py-[16px] px-[40px] ${
            selected === "EMBROIDERY" ? "bg-[#7A29FF]" : "bg-transparent"
          }`}
        >
          EMBROIDERY
        </button>
        <button
          onClick={() => setSelected("CLIENT DIARIES")}
          className={`font-[DM Sans] font-[400] text-[20px]  leading-[26.04px] text-[#FFFFFF] py-[16px] px-[40px] ${
            selected === "CLIENT DIARIES" ? "bg-[#7A29FF]" : "bg-transparent"
          }`}
        >
          CLIENT DIARIES
        </button>
        <button
          onClick={() => setSelected("DESIGN & SKETCHES")}
          className={`font-[DM Sans] font-[400] text-[20px]  leading-[26.04px] text-[#FFFFFF] py-[16px] px-[40px] ${
            selected === "DESIGN & SKETCHES" ? "bg-[#7A29FF]" : "bg-transparent"
          }`}
        >
          DESIGN & SKETCHES
        </button>
        <button
          onClick={() => setSelected("OUTFITS")}
          className={`font-[DM Sans] font-[400] text-[20px]  leading-[26.04px] text-[#FFFFFF] py-[16px] px-[40px] ${
            selected === "OUTFITS" ? "bg-[#7A29FF]" : "bg-transparent"
          }`}
        >
          OUTFITS
        </button>
      </div>
      <div className="pt-[35px] sm:px-[100px] px-[20px] overflow-hidden">
        <div className="grid sm:grid-cols-6 grid-cols-1 items-center justify-center sm:gap-[18px] gap-0 gap-y-[18px] sm:pb-[196px] pb-[60px]">
          <div className="col-span-2">
            <img
              data-aos="fade-up"
              src="assets/our/item1.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-2">
            <img
              data-aos="fade-up"
              src="assets/our/item2.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-2">
            <img
              data-aos="fade-up"
              src="assets/our/item3.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-3">
            <img
              data-aos="fade-up"
              src="assets/our/item4.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-3">
            <img
              data-aos="fade-up"
              src="assets/our/item5.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-2">
            <img
              data-aos="fade-up"
              src="assets/our/item1.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-2">
            <img
              data-aos="fade-up"
              src="assets/our/item2.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-2">
            <div className="flex flex-col gap-[18px]">
              <img
                data-aos="fade-up"
                src="assets/our/item6.png"
                className="w-full object-cover"
                alt="our-img"
              />
              <img
                data-aos="fade-up"
                src="assets/our/item7.png"
                className="w-full object-cover"
                alt="our-img"
              />
            </div>
          </div>
          <div className="col-span-2">
            <img
              data-aos="fade-up"
              src="assets/our/item1.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-2">
            <img
              data-aos="fade-up"
              src="assets/our/item2.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-2">
            <img
              data-aos="fade-up"
              src="assets/our/item3.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-3">
            <img
              data-aos="fade-up"
              src="assets/our/item4.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-3">
            <img
              data-aos="fade-up"
              src="assets/our/item5.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-2">
            <img
              data-aos="fade-up"
              src="assets/our/item1.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-2">
            <img
              data-aos="fade-up"
              src="assets/our/item2.png"
              className="w-full object-cover"
              alt="our-img"
            />
          </div>
          <div className="col-span-2">
            <div className="flex flex-col gap-[18px]">
              <img
                data-aos="fade-up"
                src="assets/our/item6.png"
                className="w-full object-cover"
                alt="our-img"
              />
              <img
                data-aos="fade-up"
                src="assets/our/item7.png"
                className="w-full object-cover"
                alt="our-img"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OurPortfolio;
