import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Aos from "aos";

function OurProcess() {
  // const [start, setStart] = useState("1");

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header />
      <div className="pt-[128px] overflow-hidden sm:px-0 px-[20px]">
        <h1
          data-aos="fade-up"
          className="font-[700] pb-[8px] text-center sm:text-[48px] text-[34px] sm:leading-[63.84px] leading-[40px] text-[#000000]"
          style={{ fontFamily: "Segoe UI, sans-serif" }}
        >
          <span className="text-[#525252]">Our&nbsp;</span>
          Process
        </h1>
        <p
          data-aos="fade-right"
          className="font-[400] pb-[75px] text-[20px] leading-[26px] text-center text-[#000000] max-w-[728px] w-full m-auto"
          style={{ fontFamily: "Segoe UI, sans-serif" }}
        >
          In 2018, Ombre Fashion started as service based company and moved
          ahead as manufacturer in 2019.
          <br />
          We as apparel manufacturing and designing company specializes in
          catering to new start-up brands and labels, offering them a one-stop
          solution for getting their products done.
        </p>
        {/* <div
          data-aos="fade-up"
          className="flex items-center justify-center pt-[28px] pb-[62px]"
        >
          <button className="font-[DM Sans] font-[500] rounded-[30px] text-[20px] leading-[26.04px] text-[#FFFFFF] py-[15px] px-[48px] bg-[#7A29FF]">
            Get Started
          </button>
        </div> */}
      </div>
      {/* <div className="sm:px-[100px] px-[20px] sm:pb-[98px] pb-[40px]">
        <div className="flex sm:flex-row flex-col w-full gap-[30px]">
          <div className="sm:max-w-[298px] max-w-full flex flex-col gap-[14px] w-full">
            <div
              onClick={() => setStart("1")}
              className={`rounded-[8px] pt-[25px] overflow-hidden h-[170px] px-[15px] transition-all duration-300 ${
                start === "1" ? "bg-[#7A29FF]" : "bg-[#9D9D9D33]"
              }`}
            >
              <h6
                data-aos="fade-down"
                className={`font-[DM Sans] font-[400] text-[40px] leading-[52.08px] transition-all duration-300 ${
                  start === "1" ? "text-[#FFFFFF]" : "text-[#525252]"
                }`}
              >
                01
              </h6>
              <p
                data-aos="fade-up"
                className={`font-[DM Sans] font-[700] text-[20px] leading-[26.04px] transition-all duration-300 ${
                  start === "1" ? "text-[#FFFFFF]" : "text-[#525252]"
                }`}
              >
                Give us a peek into your style
              </p>
            </div>
            <div
              onClick={() => setStart("2")}
              className={`rounded-[8px] overflow-hidden pt-[25px] h-[170px] px-[15px] transition-all duration-300 ${
                start === "2" ? "bg-[#7A29FF]" : "bg-[#9D9D9D33]"
              }`}
            >
              <h6
                data-aos="fade-down"
                className={`font-[DM Sans] font-[400] text-[40px] leading-[52.08px] transition-all duration-300 ${
                  start === "2" ? "text-[#FFFFFF]" : "text-[#525252]"
                }`}
              >
                02
              </h6>
              <p
                data-aos="fade-up"
                className={`font-[DM Sans] font-[700] text-[20px] leading-[26.04px] transition-all duration-300 ${
                  start === "2" ? "text-[#FFFFFF]" : "text-[#525252]"
                }`}
              >
                Approve Design Sketches / Your Ideas converted to Design
                Sketches
              </p>
            </div>
            <div
              onClick={() => setStart("3")}
              className={`rounded-[8px] overflow-hidden pt-[25px] h-[170px] px-[15px] transition-all duration-300 ${
                start === "3" ? "bg-[#7A29FF]" : "bg-[#9D9D9D33]"
              }`}
            >
              <h6
                data-aos="fade-down"
                className={`font-[DM Sans] font-[400] text-[40px] leading-[52.08px] transition-all duration-300 ${
                  start === "3" ? "text-[#FFFFFF]" : "text-[#525252]"
                }`}
              >
                03
              </h6>
              <p
                data-aos="fade-up"
                className={`font-[DM Sans] font-[700] text-[20px] leading-[26.04px] transition-all duration-300 ${
                  start === "3" ? "text-[#FFFFFF]" : "text-[#525252]"
                }`}
              >
                Give us finer details
              </p>
            </div>
            <div
              onClick={() => setStart("4")}
              className={`rounded-[8px] overflow-hidden pt-[25px] h-[170px] px-[15px] transition-all duration-300 ${
                start === "4" ? "bg-[#7A29FF]" : "bg-[#9D9D9D33]"
              }`}
            >
              <h6
                data-aos="fade-down"
                className={`font-[DM Sans] font-[400] text-[40px] leading-[52.08px] transition-all duration-300 ${
                  start === "4" ? "text-[#FFFFFF]" : "text-[#525252]"
                }`}
              >
                04
              </h6>
              <p
                data-aos="fade-up"
                className={`font-[DM Sans] font-[700] text-[20px] leading-[26.04px] transition-all duration-300 ${
                  start === "4" ? "text-[#FFFFFF]" : "text-[#525252]"
                }`}
              >
                Be ready to be dazzled as your outfit arrives at your doorstep
              </p>
            </div>
          </div>
          {start === "1" && (
            <div className="max-w-[923px] w-full overflow-hidden border border-[#E9E9E9] rounded-[8px] sm:py-[34px] py-[14px] sm:px-[38px] px-[16px]">
              <h6
                data-aos="fade-up"
                className="font-[DM Sans] font-[400] text-[20px] sm:leading-[52px] leading-[26px] text-[#7A29FF] pb-[2px]"
              >
                01
              </h6>
              <p
                data-aos="fade-right"
                className="font-[700] sm:text-[48px] text-[26px] sm:leading-[63.84px] leading-[34px] text-[#000000] pb-[25px]"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
              >
                Give us a peek into your style
              </p>
              <img data-aos="fade-left" src="assets/home/photo8.png" alt="" />
              <ul className="m-0 p-0 pt-[38px]">
                <li
                  data-aos="fade-right"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Tell us about yourself & the occasion
                </li>
                <li
                  data-aos="fade-left"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Tell us about your style & inspiration for the outfit
                </li>
                <li
                  data-aos="fade-right"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Give us details that matter - fabric, color, cut,
                  embellishments, budget
                </li>
                <li
                  data-aos="fade-left"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  We do it all by means that you prefer; email, call, whatsapp
                </li>
                <li
                  data-aos="fade-right"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Tell us about your style, inspiration and the occasion. Share
                  with us the details of fabric & color
                </li>
              </ul>
            </div>
          )}
          {start === "2" && (
            <div className="max-w-[923px] overflow-hidden w-full border border-[#E9E9E9] rounded-[8px] sm:py-[34px] py-[14px] sm:px-[38px] px-[16px]">
              <h6
                data-aos="fade-up"
                className="font-[DM Sans] font-[400] text-[20px] sm:leading-[52px] leading-[26px] text-[#7A29FF] pb-[2px]"
              >
                02
              </h6>
              <p
                data-aos="fade-right"
                className="font-[700] sm:text-[48px] text-[26px] sm:leading-[63.84px] leading-[34px] text-[#000000] pb-[25px]"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
              >
                Give us a peek into your style
              </p>
              <img data-aos="fade-left" src="assets/home/photo8.png" alt="" />
              <ul className="m-0 p-0 pt-[38px]">
                <li
                  data-aos="fade-right"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Tell us about yourself & the occasion
                </li>
                <li
                  data-aos="fade-left"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Tell us about your style & inspiration for the outfit
                </li>
                <li
                  data-aos="fade-right"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Give us details that matter - fabric, color, cut,
                  embellishments, budget
                </li>
                <li
                  data-aos="fade-left"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  We do it all by means that you prefer; email, call, whatsapp
                </li>
                <li
                  data-aos="fade-right"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Tell us about your style, inspiration and the occasion. Share
                  with us the details of fabric & color
                </li>
              </ul>
            </div>
          )}
          {start === "3" && (
            <div
              data-aos="fade-up"
              className="max-w-[923px] w-full border border-[#E9E9E9] rounded-[8px] sm:py-[34px] py-[14px] sm:px-[38px] px-[16px]"
            >
              <h6 className="font-[DM Sans] font-[400] text-[20px] sm:leading-[52px] leading-[26px] text-[#7A29FF] pb-[2px]">
                03
              </h6>
              <p
                data-aos="fade-right"
                className="font-[700] sm:text-[48px] text-[26px] sm:leading-[63.84px] leading-[34px] text-[#000000] pb-[25px]"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
              >
                Give us a peek into your style
              </p>
              <img data-aos="fade-left" src="assets/home/photo8.png" alt="" />
              <ul className="m-0 p-0 pt-[38px]">
                <li
                  data-aos="fade-right"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Tell us about yourself & the occasion
                </li>
                <li
                  data-aos="fade-left"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Tell us about your style & inspiration for the outfit
                </li>
                <li
                  data-aos="fade-right"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Give us details that matter - fabric, color, cut,
                  embellishments, budget
                </li>
                <li
                  data-aos="fade-left"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  We do it all by means that you prefer; email, call, whatsapp
                </li>
                <li
                  data-aos="fade-right"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Tell us about your style, inspiration and the occasion. Share
                  with us the details of fabric & color
                </li>
              </ul>
            </div>
          )}
          {start === "4" && (
            <div className="max-w-[923px] overflow-hidden w-full border border-[#E9E9E9] rounded-[8px] sm:py-[34px] py-[14px] sm:px-[38px] px-[16px]">
              <h6
                data-aos="fade-up"
                className="font-[DM Sans] font-[400] sm:text-[40px] text-[20px] sm:leading-[52px] leading-[26px] text-[#7A29FF] pb-[2px]"
              >
                04
              </h6>
              <p
                data-aos="fade-right"
                className="font-[700] sm:text-[48px] text-[26px] sm:leading-[63.84px] leading-[34px] text-[#000000] pb-[25px]"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
              >
                Give us a peek into your style
              </p>
              <img data-aos="fade-left" src="assets/home/photo8.png" alt="" />
              <ul className="m-0 p-0 pt-[38px]">
                <li
                  data-aos="fade-right"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Tell us about yourself & the occasion
                </li>
                <li
                  data-aos="fade-left"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Tell us about your style & inspiration for the outfit
                </li>
                <li
                  data-aos="fade-right"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Give us details that matter - fabric, color, cut,
                  embellishments, budget
                </li>
                <li
                  data-aos="fade-left"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  We do it all by means that you prefer; email, call, whatsapp
                </li>
                <li
                  data-aos="fade-right"
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="font-[400] text-[20px] leading-[26px] text-[#000000]"
                >
                  Tell us about your style, inspiration and the occasion. Share
                  with us the details of fabric & color
                </li>
              </ul>
            </div>
          )}
        </div>
      </div> */}
      <div className="relative overflow-hidden">
        <img
          src="/assets/process/FIRST.jpeg"
          className="w-full sm:h-auto h-[700px] object-cover"
          alt=""
        />
        <div className="max-w-[822px] w-full bg-[#FFFFFFD9] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] pt-[44px] px-[28px] pb-[60px]">
          <h6
            className="font-[700] sm:text-[40px] text-[24px] sm:leading-[44px] leading-[28px] text-center text-[#000000] pb-[23px]"
            style={{ fontFamily: "Segoe UI, sans-serif" }}
          >
            WHY US?
          </h6>
          <p
            style={{ fontFamily: "Segoe UI, sans-serif" }}
            className="font-[400] text-[20px] leading-[26px] text-center text-[#000000] "
          >
            What sets this company apart is its unique selling point of
            providing cost-effective work at Minimum Order Quantities (MOQ). By
            offering low MOQs, the company enables emerging brands to bring
            their designs to life without the burden of high production costs.
          </p>
        </div>
      </div>
      <div className="pt-[60px] text-[#000000] overflow-hidden font-[700] text-center sm:leading-[64px] leading-[40px] sm:text-[48px] text-[34px] max-w-[926px] mx-auto">
        <span
          style={{ fontFamily: "Segoe UI, sans-serif" }}
          className="text-[#525252]"
        >
          WE COMPLETE YOUR MANUFACTURING REQUIREMENTS&nbsp;
        </span>
        IN 7 STEPS 
      </div>
      <div className="container overflow-hidden mx-auto sm:pb-[138px] pb-[60px] mt-[64px]">
        <div className="grid sm:grid-cols-2 items-start grid-cols-1 relative sm:px-[132px] px-[20px] sm:gap-[116px] gap-[20px]">
          <div className="flex items-center justify-center flex-col border border-[#000000] pb-[16px]">
            <img
              src="/assets/process/1.jpg"
              alt=""
              className="sm:w-[250px] w-full h-[250px] object-cover"
            />
            <div className="px-[20px] py-[16px]">
              <h6
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="pt-[12px] pb-[6px] font-[700] uppercase text-[24px] leading-[24px] text-center text-[#000000]"
              >
                DESIGN PHASE
              </h6>
              <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[400] text-[20px] leading-[26px] text-center text-[#000000]"
              >
                Together we will evaluate your garment prototypes and target
                budgets. This process will involve an exchange of ideas and
                style references. We will conclude everything you require to
                start production –quantities, fabric type, designs and creating
                prototypes to bring the designs to life.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center flex-col border border-[#000000] pb-[16px]">
            <img
              src="/assets/process/2.jpg"
              alt=""
              className="sm:w-[250px] w-full h-[250px] object-cover"
            />
            <div className="px-[20px] py-[16px]">
              <h6
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="pt-[12px] pb-[6px] uppercase font-[700] text-[24px] leading-[24px] text-center text-[#000000]"
              >
                PATTERN MAKING AND GRADING
              </h6>
              <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[400] text-[20px] leading-[26px] text-center text-[#000000]"
              >
                Once the designs are finalized, the pattern making and grading
                process starts. Skilled pattern makers create the patterns based
                on the designs, ensuring proper fit and proportion. The patterns
                are then graded to create different sizes for the collection.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center flex-col border border-[#000000] pb-[16px]">
            <img
              src="/assets/process/3.jpg"
              className="sm:w-[250px] w-full h-[250px] object-cover"
              alt=""
            />
            <div className="px-[20px] py-[16px]">
              <h6
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="pt-[12px] pb-[6px] uppercase font-[700] text-[24px] leading-[24px] text-center text-[#000000]"
              >
                FABRIC SOURCING
              </h6>
              <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[400] text-[20px] leading-[26px] text-center text-[#000000]"
              >
                After the patterns are finalized, the next step is to source
                high-quality fabrics and materials that match the designer's
                vision. Luxury clothing manufacturers often source premium
                fabrics from around the world to ensure the best quality for
                their garments.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center flex-col border border-[#000000] pb-[16px]">
            <img
              src="/assets/process/4.png"
              className="sm:w-[250px] w-full h-[250px] object-cover"
              alt=""
            />
            <div className="px-[20px] py-[16px]">
              <h6
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="pt-[12px] pb-[6px] uppercase font-[700] text-[24px] leading-[24px] text-center text-[#000000]"
              >
                CUTTING & SEWING
              </h6>
              <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[400] text-[20px] leading-[26px] text-center text-[#000000]"
              >
                The cutters then use the patterns to cut the fabric into the
                desired shapes and sizes. Skilled sewers then meticulously sew
                the cut pieces together, paying attention to every detail to
                ensure the highest quality craftsmanship.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center flex-col border border-[#000000] pb-[16px]">
            <img
              src="/assets/process/5.jpg"
              className="sm:w-[250px] w-full h-[250px] object-cover"
              alt=""
            />
            <div className="px-[20px] py-[16px]">
              <h6
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="pt-[12px] pb-[6px] uppercase font-[700] text-[24px] leading-[24px] text-center text-[#000000]"
              >
                DESIGN & SAMPLE APPROVAL
              </h6>
              <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[400] text-[20px] leading-[26px] text-center text-[#000000]"
              >
                Based on designs and prototypes finalised, we will prepare a
                sample batch of your order. This will include all fit samples,
                size sets, labels, embellishments, buttons and hand tags, using
                the actual fabric finalised in step 3 to guarantee all standard
                quality requirements are well met with prior to bulk production
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center flex-col border border-[#000000] pb-[16px]">
            <img
              src="/assets/process/6.jpg"
              className="sm:w-[250px] w-full h-[250px] object-cover"
              alt=""
            />
            <div className="px-[20px] py-[16px]">
              <h6
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="pt-[12px] pb-[6px] uppercase font-[700] text-[24px] leading-[24px] text-center text-[#000000]"
              >
                QUALITY CONTROL
              </h6>
              <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[400] text-[20px] leading-[26px] text-center text-[#000000]"
              >
                It is a crucial step in the manufacturing process. Each garment
                is carefully inspected for any defects or imperfections before
                moving on to the next stage. Any issues are addressed and
                corrected to maintain the high standards of the luxury brand.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center flex-col border border-[#000000] pb-[16px]">
            <img
              src="/assets/process/7.jpg"
              className="sm:w-[250px] w-full h-[250px] object-cover"
              alt=""
            />
            <div className="px-[20px] py-[16px]">
              <h6
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="pt-[12px] pb-[6px] uppercase font-[700] text-[24px] leading-[24px] text-center text-[#000000]"
              >
                PACKAGING, DOCUMENTATION AND SHIPMENT
              </h6>
              <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[400] text-[20px] leading-[26px] text-center text-[#000000]"
              >
                The final step is to carefully package the finished garments in
                luxurious packaging that reflects the brand's image. The
                garments are then shipped to retailers or directly to customers,
                ready to
                {/* be enjoyed and cherished. */}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center flex-col border border-[#000000] pb-[16px]">
            <img
              src="/assets/photo13.png"
              className="sm:w-[250px] w-full h-[250px] object-cover"
              alt=""
            />
            <div className="px-[20px] py-[16px]">
              <h6
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="pt-[12px] pb-[6px] font-[700] uppercase text-[24px] leading-[24px] text-center text-[#000000]"
              >
                LETS CO-CREATING GREAT FASHION TOGETHER
              </h6>
            </div>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[20px] leading-[26px] text-center text-[#000000]"
            >
              From design concept to final product, we work closely with each
              client to ensure that their vision is translated into quality
              garments that meet both budget and quantity requirements. This
              approach not only helps new start-ups launch their brands
              successfully but also fosters creativity and innovation within the
              fashion industry.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#000000] overflow-hidden sm:pt-[72px] pt-[30px] sm:pb-[128px] pb-[40px] sm:px-[80px] px-[20px]">
        <p
          style={{ fontFamily: "Segoe UI, sans-serif" }}
          className="max-w-[965px] mx-auto sm:pb-[80px] pb-[40px] font-[400] text-[20px] leading-[26px] text-center text-[#FFFFFF]"
        >
          Every great fashion collection begins as a spark of imagination, a
          dream waiting to be realized. In the world of garment manufacturing,
          the journey from concept to catwalk is intricate and exhilarating.
          Whether you’re a budding designer or an established brand,
          understanding this process is crucial to turning your creative vision
          into a tangible, marketable collection.
        </p>
        <div className="grid sm:grid-cols-4 grid-cols-1 gap-[30px]">
          <div className="bg-[#1D1C20] rounded-[8px] pt-[45px] px-[16px]">
            <h6 className="font-sans font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]">
              Research and Inspiration
            </h6>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[16px] leading-[22px] text-[#94949E] pb-[46px]"
            >
              Every collection starts with research and inspiration. Designers
              immerse themselves in trend forecasting, cultural themes, and
              artistic influences. Whether it’s a historical era, a work of art,
              or a natural phenomenon, the key is to translate this inspiration
              into a wearable form.
            </p>
          </div>
          <div className="bg-[#1D1C20] rounded-[8px] pt-[45px] px-[16px]">
            <h6 className="font-sans font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]">
              Create Sketches
            </h6>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[16px] leading-[22px] text-[#94949E] pb-[46px]"
            >
              Sketching is where ideas begin to take shape. Using a combination
              of digital tools and traditional hand-drawing techniques,
              designers experiment with silhouettes, details, and textures.
              These sketches are the blueprints for what will become the final
              garments.
            </p>
          </div>
          <div className="bg-[#1D1C20] rounded-[8px] pt-[45px] px-[16px]">
            <h6 className="font-sans font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]">
              Design and Development
            </h6>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[16px] leading-[22px] text-[#94949E] pb-[46px]"
            >
              In this phase, sketches evolve into prototypes. Working closely
              with design manufacturers, designers refine their concepts, select
              fabrics, and experiment with different cuts and constructions.
              This collaborative process is crucial to ensure that the designs
              are not only aesthetically pleasing but also practical and
              manufacturable.
            </p>
          </div>
          <div className="bg-[#1D1C20] rounded-[8px] pt-[45px] px-[16px]">
            <h6 className="font-sans font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]">
              Costing and Budgeting
            </h6>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[16px] leading-[22px] text-[#94949E] pb-[46px]"
            >
              Cost is a critical factor in clothing production. Designers must
              balance creative aspirations with financial realities, determining
              the cost of materials, labor, and other resources. Effective
              budgeting ensures that the collection is financially viable
              without compromising on quality.
            </p>
          </div>
          <div className="bg-[#1D1C20] rounded-[8px] pt-[45px] px-[16px]">
            <h6 className="font-sans font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]">
              Production Planning
            </h6>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[16px] leading-[22px] text-[#94949E] pb-[46px]"
            >
              Once the designs are finalized, production planning begins. This
              involves scheduling, resource allocation, and coordination with
              apparel manufacturers. Good planning is essential to meet
              deadlines and ensure that the manufacturing process runs smoothly.
            </p>
          </div>
          <div className="bg-[#1D1C20] rounded-[8px] pt-[45px] px-[16px]">
            <h6 className="font-sans font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]">
              Pattern Making
            </h6>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[16px] leading-[22px] text-[#94949E] pb-[46px]"
            >
              Pattern-making is an art in itself. Expert pattern makers
              translate design sketches into patterns that will be used to cut
              the fabric. This step is crucial for achieving the right fit and
              style of the garments.
            </p>
          </div>
          <div className="bg-[#1D1C20] rounded-[8px] pt-[45px] px-[16px]">
            <h6 className="font-sans font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]">
              Fabric and Trim Sourcing
            </h6>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[16px] leading-[22px] text-[#94949E] pb-[46px]"
            >
              Sourcing the right fabric and trims is pivotal. Designers seek out
              the best textile manufacturers in the USA and globally, focusing
              on quality, sustainability, and suitability for their designs. The
              choice of fabric can significantly influence the look and feel of
              the final garment.
            </p>
          </div>
          <div className="bg-[#1D1C20] rounded-[8px] pt-[45px] px-[16px]">
            <h6 className="font-sans font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]">
              Quality Control
            </h6>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[16px] leading-[22px] text-[#94949E] pb-[46px]"
            >
              Quality control is integral in garment manufacturing. This process
              ensures that each piece meets the brand’s standards. From the
              stitching to the final fit, each detail is scrutinized for
              excellence.
            </p>
          </div>
          <div className="bg-[#1D1C20] rounded-[8px] pt-[45px] px-[16px]">
            <h6 className="font-sans font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]">
              Sizing and Measurement Guide
            </h6>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[16px] leading-[22px] text-[#94949E] pb-[46px]"
            >
              Accurate sizing is essential for the wearability of a collection.
              Designers develop comprehensive sizing and measurement guides to
              ensure that their garments fit a range of body types, adhering to
              industry standards and market expectations.
            </p>
          </div>
          <div className="bg-[#1D1C20] rounded-[8px] pt-[45px] px-[16px]">
            <h6 className="font-sans font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]">
              Cutting Process
            </h6>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[16px] leading-[22px] text-[#94949E] pb-[46px]"
            >
              The cutting process is where the fabric is transformed into
              garment pieces. Precision and skill are crucial here, as the way
              fabric is cut can affect the drape and fit of the finished
              garment.
            </p>
          </div>
          <div className="bg-[#1D1C20] rounded-[8px] pt-[45px] px-[16px]">
            <h6 className="font-sans font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]">
              Know Your Target Audience
            </h6>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[16px] leading-[22px] text-[#94949E] pb-[46px]"
            >
              Understanding your target audience is key to a successful
              collection. Designers must be attuned to the preferences,
              lifestyles, and needs of their intended customers, ensuring that
              their collection resonates and meets market demand.
            </p>
          </div>
          <div className="bg-[#1D1C20] rounded-[8px] pt-[45px] px-[16px]">
            <h6 className="font-sans font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]">
              Final Pricing and Branding
            </h6>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[16px] leading-[22px] text-[#94949E] pb-[46px]"
            >
              Finalizing pricing involves considering production costs, market
              positioning, and perceived value. Branding, on the other hand, is
              about creating a unique identity and narrative for the collection,
              essential for marketing and establishing a connection with the
              audience.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OurProcess;
