import React, { useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Aos from "aos";
import { useNavigate } from "react-router-dom";

function OurStory() {
  // const [selected, setSelected] = useState("All");
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header />
      <div className="relative overflow-hidden mt-[96px]">
        <img
          src="assets/photo3.jpg"
          className="w-full sm:h-[100vh] h-[400px] object-cover"
          alt=""
        />
        <div className="absolute top-[50%] left-[50%] flex justify-center translate-x-[-50%] w-full translate-y-[-50%]">
          <div className="bg-[#FFFFFFD9] overflow-hidden max-w-[822px] w-full sm:py-[71px] py-[20px] sm:px-[27px] px-[14px]">
            <h6
              data-aos="fade-down"
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[700] sm:text-[40px] text-[30px] sm:leading-[44px] leading-[36px] text-[#000000] text-center"
            >
              Our Indian Essence focuses on exquisite Karigari & embodies a
              beautiful handcrafted soul ! About The Brand
            </h6>
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[500] sm:text-[20px] mt-[20px] text-[14px] sm:leading-[24px] leading-[20px] text-[#000000] text-center"
            >
              As a small-scale manufacturing powerhouse catering to start-up
              brands that focus on all categories - men, women, and kids - we
              pride ourselves on delivering designer-level work with unmatched
              quality and attention to detail.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#FFFFFF] overflow-hidden px-[20px] sm:px-0 py-[52px]">
        <h6
          data-aos="fade-up"
          style={{ fontFamily: "Segoe UI, sans-serif" }}
          className="font-[700] sm:text-[48px] text-[38px] sm:leading-[63.84px] leading-[46px] text-[#525252] text-center pb-[16px]"
        >
          About The Brand
        </h6>
        <div className="max-w-[864px] m-auto w-full">
          <p
            data-aos="fade-right"
            style={{ fontFamily: "Segoe UI, sans-serif" }}
            className="font-[400] text-[20px] leading-[26px] text-center pb-[24px] text-[#000000]"
          >
            We understand Indian women and their unique individuality. Every
            women has a distinct personality and she expresses it through her
            original style. Then why do they need to wear same run of the mill
            outfits which comes in standard sizes and same designs , which many
            other people are already wearing. She is unique and she needs a
            unique style statement. We customize and create bespoke outfits
            suited to her individual style and create an outfit which is her own
            in true sense, designed and crafted only for her.
          </p>
          <p
            data-aos="fade-left"
            style={{ fontFamily: "Segoe UI, sans-serif" }}
            className="font-[400] text-[20px] leading-[26px] text-center pb-[24px] text-[#000000]"
          >
            We understand their need to look their best in every dress they
            adorn. Personified with their style and expression a women’s
            imagination and creativity knows no bounds. Very diligently we
            convert your imagination into reality through our Bespoke designing
            and impeccable fit crafted in luxury fabrics and high-end
            craftsmanship, custom made to your measurements and delivered right
            at your doorsteps, right in time for you to dazzle the world every
            single time.
          </p>
        </div>
      </div>

      <div className="bg-[#000000] overflow-hidden pt-[100px] pb-[70px]">
        <div className="overflow-hidden lg:pb-[132px]">
          <div className="flex sm:flex-row flex-col sm:justify-end justify-center relative sm:gap-0 gap-[40px]">
            <img src="/assets/home/end-picture.jpg" data-aos="fade-up" alt="" />
            <div className="absolute sm:block overflow-hidden hidden bg-[#7A29FF] rounded-tr-[114px] border-[8px] border-[#FFFFFF] pt-[53px] max-w-[769px] w-full left-[80px] px-[50px]">
              <img
                src="assets/item/logo.svg"
                className="absolute right-[49px] bottom-[33px]"
                alt=""
              />
              <h6
                data-aos="fade-left"
                className="font-[DM Sans] font-[700] sm:text-[40px] text-[24px] sm:leading-[38px] leading-[24px] text-[#FFFFFF] pb-[27px]"
              >
                Design Philosophy
              </h6>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="max-w-[597px] w-full font-[400] text-[16px] leading-[20px] text-[#FFFFFF] pb-[24px]"
              >
                Bespoke being our forte, you are the designer we listen to and
                follow. We step in to make your designs breathtakingly beautiful
                with the detail oriented design team & excellent craftsmanship
                but you call the shots. We take pride in our transparent process
                which lets you see and approve the dress at every stage and make
                changes, if need be.
              </p>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="max-w-[597px] w-full font-[400] text-[16px] leading-[20px] text-[#FFFFFF] pb-[48px]"
              >
                From concept to creation, we work closely with each brand to
                ensure that every piece we produce not only meets but exceeds
                their expectations.
              </p>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="max-w-[597px] w-full font-[400] text-[16px] leading-[20px] text-[#FFFFFF] pb-[48px]"
              >
                Whether you're a start-up brand looking to make a mark in the
                industry or an established label seeking a reliable
                manufacturing partner, we are here to bring your ideas to life.
                Partner with us, and together we can create fashion-forward,
                high-quality pieces that will captivate audiences and elevate
                your brand to new heights.
              </p>
              <button
                data-aos="fade-up"
                onClick={() => navigate("/our-process")}
                className="mb-[68px] font-[DM Sans] font-[500] cursor-pointer text-[20px] transition-all duration-300 bg-[#000000] hover:bg-[#FFFFFF] text-[#FFFFFF] hover:text-[#000000] py-[15px] px-[27px] rounded-[30px] border border-[#FFFFFF]"
              >
                Explore Our Process
              </button>
            </div>
            <div className="relative block sm:hidden bg-[#7A29FF] border-[8px] border-[#FFFFFF] pt-[53px] max-w-[769px] w-full sm:px-[50px] px-[20px]">
              <img
                src="assets/item/logo.svg"
                className="absolute right-[49px] bottom-[33px]"
                alt=""
              />
              <h6
                data-aos="fade-left"
                className="font-[DM Sans] font-[700] sm:text-[40px] text-[24px] sm:leading-[38px] leading-[24px] text-[#FFFFFF] pb-[27px]"
              >
                Design Philosophy
              </h6>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="max-w-[597px] w-full font-[400] text-[16px] leading-[20px] text-[#FFFFFF] pb-[24px]"
              >
                Bespoke being our forte, you are the designer we listen to and
                follow. We step in to make your designs breathtakingly beautiful
                with the detail oriented design team & excellent craftsmanship
                but you call the shots. We take pride in our transparent process
                which lets you see and approve the dress at every stage and make
                changes, if need be.
              </p>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="max-w-[597px] w-full font-[400] text-[16px] leading-[20px] text-[#FFFFFF] pb-[48px]"
              >
                From concept to creation, we work closely with each brand to
                ensure that every piece we produce not only meets but exceeds
                their expectations.
              </p>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="max-w-[597px] w-full font-[400] text-[16px] leading-[20px] text-[#FFFFFF] pb-[48px]"
              >
                Whether you're a start-up brand looking to make a mark in the
                industry or an established label seeking a reliable
                manufacturing partner, we are here to bring your ideas to life.
                Partner with us, and together we can create fashion-forward,
                high-quality pieces that will captivate audiences and elevate
                your brand to new heights.
              </p>
              <button
                data-aos="fade-up"
                onClick={() => navigate("/our-process")}
                className="mb-[68px] font-[DM Sans] font-[500] text-[20px] cursor-pointer transition-all duration-300 bg-[#000000] hover:bg-[#FFFFFF] text-[#FFFFFF] hover:text-[#000000] py-[15px] px-[27px] rounded-[30px] border border-[#FFFFFF]"
              >
                Explore Our Process
              </button>
            </div>
          </div>
        </div>
        <div className="overflow-hidden sm:pt-0 pt-[40px]">
          <div className="flex sm:flex-row flex-col sm:justify-start justify-center relative sm:gap-0 gap-[40px]">
            <img src="assets/photo4.png" data-aos="fade-up" alt="" />
            <div className="absolute sm:block overflow-hidden hidden bg-[#7A29FF] rounded-tr-[114px] border-[8px] border-[#FFFFFF] pt-[53px] max-w-[769px] w-full bottom-[39px] right-[80px] px-[50px]">
              <img
                src="assets/item/logo.svg"
                className="absolute right-[49px] bottom-[33px]"
                alt=""
              />
              {/* <p
              data-aos="fade-right"
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[700] text-[16px] leading-[21.28px] text-[#FFFFFFCC] pb-[10px]"
            >
              LOOKING FOR THE BEST CUSTOM CLOTHING MANUFACTURER IN INDIA?
            </p> */}
              <h6
                data-aos="fade-up"
                className="font-[DM Sans] font-[700] sm:text-[40px] text-[24px] sm:leading-[38px] leading-[24px] text-[#FFFFFF] pb-[27px]"
              >
                Our Workshop
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="max-w-[597px] w-full font-[400] text-[16px] leading-[20px] text-[#FFFFFF] pb-[24px]"
              >
                Our team of talented designers and artisans are passionate about
                their craft, bringing creativity and artistry to every garment
                they create. From the initial concept sketches to the final
                stitch, each piece is lovingly handcrafted with precision and
                care, resulting in a finished product that is truly
                one-of-a-kind.
              </p>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="max-w-[597px] w-full font-[400] text-[16px] leading-[20px] text-[#FFFFFF] pb-[48px]"
              >
                Whether you're looking for bespoke evening gowns, high-end
                tailored suits, or luxurious accessories, we are here to bring
                your vision to life. Collaborate with us, and experience the
                unparalleled beauty and sophistication of handcrafted luxury
                products that are sure to captivate and inspire.
              </p>
              <button
                data-aos="fade-up"
                onClick={() => navigate("/our-portfolio")}
                className="mb-[68px] font-[DM Sans] font-[500] cursor-pointer text-[20px] transition-all duration-300 bg-[#000000] hover:bg-[#FFFFFF] text-[#FFFFFF] hover:text-[#000000] py-[15px] px-[27px] rounded-[30px] border border-[#FFFFFF]"
              >
                Explore Portfolio
              </button>
            </div>
            <div className="relative block sm:hidden bg-[#7A29FF] border-[8px] border-[#FFFFFF] pt-[53px] max-w-[769px] w-full sm:px-[50px] px-[20px]">
              <img
                src="assets/item/logo.svg"
                className="absolute right-[49px] bottom-[33px]"
                alt=""
              />
              <h6
                data-aos="fade-up"
                className="font-[DM Sans] font-[700] sm:text-[40px] text-[24px] sm:leading-[38px] leading-[24px] text-[#FFFFFF] pb-[27px]"
              >
                Our Workshop
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="max-w-[597px] w-full font-[400] text-[16px] leading-[20px] text-[#FFFFFF] pb-[24px]"
              >
                Our team of talented designers and artisans are passionate about
                their craft, bringing creativity and artistry to every garment
                they create. From the initial concept sketches to the final
                stitch, each piece is lovingly handcrafted with precision and
                care, resulting in a finished product that is truly
                one-of-a-kind.
              </p>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="max-w-[597px] w-full font-[400] text-[16px] leading-[20px] text-[#FFFFFF] pb-[48px]"
              >
                Whether you're looking for bespoke evening gowns, high-end
                tailored suits, or luxurious accessories, we are here to bring
                your vision to life. Collaborate with us, and experience the
                unparalleled beauty and sophistication of handcrafted luxury
                products that are sure to captivate and inspire.
              </p>
              <button
                data-aos="fade-up"
                onClick={() => navigate("/our-portfolio")}
                className="mb-[68px] font-[DM Sans] font-[500] text-[20px] transition-all duration-300 bg-[#000000] hover:bg-[#FFFFFF] text-[#FFFFFF] hover:text-[#000000] py-[15px] px-[27px] rounded-[30px] border border-[#FFFFFF]"
              >
                Explore Portfolio
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#FFFFFF] overflow-hidden px-[20px] sm:px-0 py-[52px]">
        <div className="flex justify-center pb-[50px]">
          <img
            src="/assets/team.jpg"
            className="h-[500px] object-cover"
            alt=""
          />
        </div>
        <h6
          data-aos="fade-up"
          style={{ fontFamily: "Segoe UI, sans-serif" }}
          className="font-[700] sm:text-[48px] text-[38px] sm:leading-[63.84px] leading-[46px] text-[#525252] text-center pb-[16px]"
        >
          <span className="text-[#525252]"> Our</span> Team
        </h6>
        <div className="max-w-[864px] m-auto w-full">
          <p
            data-aos="fade-left"
            style={{ fontFamily: "Segoe UI, sans-serif" }}
            className="font-[400] text-[20px] leading-[26px] text-center pb-[24px] text-[#000000]"
          >
            We take pride in our extremely skilled and professional team of
            Design Karigars, Style Karigars Embroidery Karigars, Stitching
            Karigars, Pattern Karigars, Khaka Karigars, Dye Karigars and
            Finishing Karigars.
          </p>
          <p
            data-aos="fade-right"
            style={{ fontFamily: "Segoe UI, sans-serif" }}
            className="font-[400] text-[20px] leading-[26px] text-center pb-[24px] text-[#000000]"
          >
            We believe every person working here for the creative pursuit, be it
            a Designer, Stylist, Tailor, Master or Embroider, everyone is a
            Karigar (Craftsman) in its own sense and every task they perform to
            complete your dream ensemble is a craft in itself !
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OurStory;
