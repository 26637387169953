import React, { useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import AOS from "aos";
import Custom1 from "../components/slider/Custom1";
import Custom2 from "../components/slider/Custom2";
import Custom3 from "../components/slider/Custom3";

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header />
      <div className="hero-section overflow-hidden">
        <img
          data-aos="fade-left"
          src="assets/home/icon2.svg"
          className="absolute sm:block hidden right-0 top-[40px] h-[853px] w-[426px] -z-0"
          alt=""
        />
        <img
          data-aos="fade-left"
          src="assets/home/icon1.svg"
          className="absolute sm:block hidden right-0 top-[80px] h-[734px] w-[367px] -z-0"
          alt=""
        />
        <div className="flex flex-col items-center justify-center">
          <button
            data-aos="fade-down"
            className="font-[600] mt-[46px] mb-[26px] py-[10px] px-[30px] bg-[#FFFFFF] border border-[#000000] rounded-[30px] text-[18px] leading-[26px] text-[#000000]"
            style={{ fontFamily: "Segoe UI, sans-serif" }}
          >
            Finding a clothing manufacturer?
          </button>
          <p
            data-aos="fade-right"
            className="text-[#FFFFFF] flex sm:flex-row flex-col items-center font-[DM Sans] font-[700] sm:text-[48px] text-[30px] sm:leading-[62.5px] leading-[36px] text-center"
          >
            We Design. We
            <span className="sm:pt-0">
              <img src="assets/item/icon5.svg" className="ml-[26px]" alt="" />
            </span>
          </p>
          <p
            data-aos="fade-left"
            style={{ fontFamily: "Segoe UI, sans-serif" }}
            className="max-w-[733px] m-auto font-[400] text-[16px] leading-[20px] text-[#FFFFFF] pt-[16px] pb-[36px] text-center"
          >
            Offering high-quality apparel products for local and international
            markets at very competitive prices.
          </p>
          <div className="grid sm:grid-cols-5 grid-cols-1 gap-[23px] items-center relative z-10">
            <div>
              <img
                data-aos="fade-up"
                src="assets/slide1/img1.jpg"
                className="h-[226px] object-cover w-[226px] rounded-[14px]"
                alt=""
              />
            </div>
            <div className="flex flex-col gap-[23px]">
              <img
                data-aos="fade-down"
                className="h-[164px] object-cover w-full rounded-[14px]"
                src="assets/slide1/img2.jpg"
                alt=""
              />
              <img
                data-aos="fade-up"
                className="h-[256px] object-cover w-full rounded-[14px]"
                src="assets/slide1/img3.jpg"
                alt=""
              />
            </div>
            <div>
              <img
                className="h-[488px] object-cover w-full rounded-[14px]"
                src="assets/slide1/img7.jpg"
                data-aos="fade-down"
                alt=""
              />
            </div>
            <div className="flex flex-col gap-[23px]">
              <img
                data-aos="fade-up"
                className="h-[268px] object-cover w-full rounded-[14px]"
                src="assets/slide1/img4.jpg"
                alt=""
              />
              <img
                data-aos="fade-down"
                className="h-[170px] object-cover w-full rounded-[14px]"
                src="assets/slide1/img5.jpg"
                alt=""
              />
            </div>
            <div>
              <img
                data-aos="fade-up"
                className="h-[225px] object-cover w-full rounded-[14px]"
                src="assets/slide1/img6.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#000000] overflow-hidden sm:pt-[100px] pt-[20px] relative sm:px-[100px] px-[20px]">
        <img
          data-aos="fade-up"
          src="assets/home/icon3.svg"
          className="absolute sm:block hidden bottom-0 h-[230px] w-[459px] -z-0"
          alt=""
        />
        <img
          data-aos="fade-up"
          src="assets/home/icon4.svg"
          className="absolute sm:block hidden bottom-0 left-[250px] h-[230px] w-[459px] -z-0"
          alt=""
        />
        <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-[180px] gap-[60px]">
          <div className="pt-[58px]">
            <h6
              data-aos="fade-left"
              className="font-[700] sm:text-[48px] text-[30px] sm:leading-[54px] leading-[36px] text-[#FFFFFF] pb-[26px]"
              style={{ fontFamily: "Segoe UI, sans-serif" }}
            >
              About OMBRE
            </h6>
            <p
              data-aos="fade-right"
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[20px] leading-[24px] text-[#FFFFFF]"
            >
              YOUR ALL-IN-1 SOLUTION TO APPAREL DESIGN AND MANUFACTURING “From
              garment creation to brand elevation we are all-in-1 solution for
              apparel manufacturing and brand success”
            </p>
          </div>
          <div className="flex justify-center items-start">
            <img data-aos="fade-left" src="assets/home/about-img.jpg" alt="" />
          </div>
        </div>
      </div>
      <div className="bg-[#FFFFFF] overflow-hidden py-[98px]">
        <p
          data-aos="fade-up"
          className="text-center text-[#7A29FF] font-[DM Sans] font-[500] text-[24px] leading-[32px]"
        >
          Step By Step Process
        </p>
        <h6
          data-aos="fade-down"
          className="font-[700] sm:text-[48px] text-[30px] sm:leading-[63.84px] leading-[36px] text-Center text-center pt-[8px]"
          style={{ fontFamily: "Segoe UI, sans-serif" }}
        >
          We Complete every Step Carefully
        </h6>
      </div>
      <div className="card-section overflow-hidden">
        <div className="grid sm:grid-cols-4 grid-cols-1">
          <div className="relative w-full">
            <img
              src="assets/slide1/img11.jpg"
              className="w-full h-full object-cover"
              alt=""
            />
            <div
              data-aos="fade-left"
              className="absolute bottom-[60px] left-[36px]"
            >
              <h6 className="font-[DM Sans] font-[400] text-[40px] leading-[52.08px] text-[#FFFFFF]">
                01
              </h6>
              <p className="font-[DM Sans] font-[700] text-[34px] leading-[44.27px] text-[#FFFFFF]">
                Inquiry Design
              </p>
            </div>
          </div>
          <div className="relative w-full">
            <img
              src="assets/slide1/img12.jpg"
              className="w-full h-full object-cover"
              alt=""
            />
            <div
              data-aos="fade-left"
              className="absolute bottom-[60px] left-[36px]"
            >
              <h6 className="font-[DM Sans] font-[400] text-[40px] leading-[52.08px] text-[#FFFFFF]">
                02
              </h6>
              <p className="font-[DM Sans] font-[700] text-[34px] leading-[44.27px] text-[#FFFFFF]">
                Design Planning
              </p>
            </div>
          </div>
          <div className="relative w-full">
            <img
              src="assets/slide1/img13.png"
              className="w-full h-full object-cover"
              alt=""
            />
            <div
              data-aos="fade-left"
              className="absolute bottom-[60px] left-[36px]"
            >
              <h6 className="font-[DM Sans] font-[400] text-[40px] leading-[52.08px] text-[#FFFFFF]">
                03
              </h6>
              <p className="font-[DM Sans] font-[700] text-[34px] leading-[44.27px] text-[#FFFFFF]">
                Quality Control
              </p>
            </div>
          </div>
          <div className="relative w-full">
            <img
              src="assets/slide1/img14.jpg"
              className="w-full h-[564px] object-cover"
              alt=""
            />
            <div
              data-aos="fade-left"
              className="absolute bottom-[60px] left-[36px]"
            >
              <h6 className="font-[DM Sans] font-[400] text-[40px] leading-[52.08px] text-[#FFFFFF]">
                04
              </h6>
              <p className="font-[DM Sans] font-[700] text-[34px] leading-[44.27px] text-[#FFFFFF]">
                Sample Approval
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#000000] overflow-hidden sm:pt-[136px] pt-[40px] sm:px-[76px] px-[20px] sm:pb-[124px] pb-[40px]">
        <p
          data-aos="fade-left"
          className="font-[DM Sans] font-[700] text-[24px] leading-[31.25px] text-[#7A29FF] pb-[12px]"
        >
          Best-in-class
        </p>
        <h6
          data-aos="fade-right"
          style={{ fontFamily: "Segoe UI, sans-serif" }}
          className="font-[700] sm:text-[48px] text-[30px] sm:leading-[52px] leading-[36px] text-[#FFFFFF]"
        >
          Why Clothing Brands <br /> Choose Us ?
        </h6>
        <p
          data-aos="fade-left"
          className="max-w-[537px] w-full font-[400] text-[20px] leading-[26px] text-[#FFFFFF] pt-[18px] pb-[48px]"
          style={{ fontFamily: "Segoe UI, sans-serif" }}
        >
          As expert craftsmen, we offer top-notch quality, reliability, and
          expertise in the industry. Our team is equipped with skilled
          professionals who pay meticulous attention to detail, ensuring that
          every piece of clothing meets the highest standards.
        </p>
        <div className="grid sm:grid-cols-4 grid-cols-1 gap-[30px]">
          <div className="relative bg-[#1D1C20] rounded-[8px] pb-[18px] pt-[45px]">
            <img
              src="assets/home/icon5.svg"
              className="absolute top-[-21px] right-[24px]"
              alt=""
            />
            <div className="px-[16px]">
              <h6
                data-aos="fade-left"
                className="font-[DM Sans] font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]"
              >
                Custom Fabric Construction
              </h6>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[400] text-[16px] leading-[21.28px] text-[#94949E] pb-[26px]"
              >
                We as clothing manufacturer secure your designs from the moment
                we receive your tech packs & design details unlike sourcing
                agents who share your designs with several inexperienced
                manufacturers leading to a high possibility of plagiarism.
              </p>
            </div>
            <div className="h-[2px] absolute bottom-[18px] bg-[#7A29FF] w-full"></div>
          </div>
          <div className="relative bg-[#1D1C20] rounded-[8px] pb-[18px] pt-[45px]">
            <img
              src="assets/home/icon6.svg"
              className="absolute top-[-21px] right-[24px]"
              alt=""
            />
            <div className="px-[16px]">
              <h6
                data-aos="fade-left"
                className="font-[DM Sans] font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]"
              >
                Custom Design
              </h6>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[400] text-[16px] leading-[21.28px] text-[#94949E] pb-[26px]"
              >
                Our craftsmen and women are skilled in developing garments as
                per the tech packs with a minimum of 99% accuracy in the first
                sample development process itself. Most of them have over two
                decades of experience.
              </p>
            </div>
            <div className="h-[2px] absolute bottom-[18px] bg-[#7A29FF] w-full"></div>
          </div>
          <div className="relative bg-[#1D1C20] rounded-[8px] pb-[18px] pt-[45px]">
            <img
              src="assets/home/icon7.svg"
              className="absolute top-[-21px] right-[24px]"
              alt=""
            />
            <div className="px-[16px]">
              <h6
                data-aos="fade-left"
                className="font-[DM Sans] font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]"
              >
                Custom Trims
              </h6>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[400] text-[16px] leading-[21.28px] text-[#94949E] pb-[26px]"
              >
                As per the buyer's requirements we can source custom made
                clothing fabric, accessories and trim that puts your brand our
                par with international brands.
              </p>
            </div>
            <div className="h-[2px] absolute bottom-[18px] bg-[#7A29FF] w-full"></div>
          </div>
          <div className="relative bg-[#1D1C20] rounded-[8px] pb-[18px] pt-[45px]">
            <img
              src="assets/home/icon8.svg"
              className="absolute top-[-21px] right-[24px]"
              alt=""
            />
            <div className="px-[16px]">
              <h6
                data-aos="fade-left"
                className="font-[DM Sans] font-[700] text-[20px] leading-[26.04px] text-[#FFFFFF] pb-[4px]"
              >
                Door-to-door Delivery
              </h6>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[400] text-[16px] leading-[21.28px] text-[#94949E] pb-[26px]"
              >
                We have a proven track record of delivering on time and
                exceeding expectations, which is crucial for brands looking to
                build a strong reputation in the market.
              </p>
            </div>
            <div className="h-[2px] absolute bottom-[18px] bg-[#7A29FF] w-full"></div>
          </div>
        </div>
      </div>
      <div className="bg-[#FFFFFF] overflow-hidden sm:pt-[96px] pt-[40px] sm:px-[77px] px-[20px]">
        <h6
          data-aos="fade-left"
          style={{ fontFamily: "Segoe UI, sans-serif" }}
          className="font-[700] sm:text-[48px] text-[30px] sm:leading-[52px] leading-[36px] text-[#000000] pb-[17px]"
        >
          Get Power start to set <br /> your clothing brand?
        </h6>
        <p
          data-aos="fade-right"
          className="max-w-[537px] w-full font-[400] text-[20px] leading-[26px] text-[#000000]"
          style={{ fontFamily: "Segoe UI, sans-serif" }}
        >
          To get started with turning your clothing design into a reality with
          an apparel manufacturing company, here are some steps you can follow:
        </p>
        <div className="pt-[98px] pb-[144px]">
          <div className="grid sm:grid-cols-3 grid-cols-1 sm:gap-[30px] gap-[100px]">
            <div className="relative bg-[#FFFFFF] border border-[#E9E9E9] rounded-[16px] px-[10px] pt-[18px]">
              <div
                style={{ boxShadow: "0px 7px 29px 0px #64646F33" }}
                className="h-[92px] bg-[#FFFFFF] w-[92px] py-[20px] px-[22px] rounded-full font-[DM Sans] font-[700] text-[40px] leading-[52.08px] text-[#212121] absolute left-[50%] translate-x-[-50%] top-[-46px]"
              >
                #1
              </div>
              <div className="flex justify-end pb-[24px]">
                <button
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="bg-[#000000] rounded-[4.62px] px-[14px] py-[1px] font-[600] text-[16.15px] leading-[30px] text-[#FFFFFF]"
                >
                  1 Week TAT
                </button>
              </div>
              <h6
                data-aos="fade-left"
                className="font-[DM Sans] pl-[17px] font-[700] text-[26px] leading-[33.85px] text-[#212121]"
              >
                Your Idea
              </h6>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[14px] leading-[26px] text-[#94949E] pl-[17px] pb-[22px]"
              >
                Blueprint, Designs & Tech Packs
              </p>
              <div className="border-dashed border relative border-[#000000]">
                <img
                  src="assets/home/icon9.svg"
                  className="absolute left-[-20px] top-[50%] translate-y-[-50%]"
                  alt=""
                />
                <img
                  src="assets/home/icon10.svg"
                  className="absolute right-[-20px] top-[50%] translate-y-[-50%]"
                  alt=""
                />
              </div>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[16px] leading-[26px] text-[#212121] pl-[21px] pt-[15px] pb-[25px]"
              >
                It all starts with you providing us with a blueprint of your
                clothing idea for us to study.
              </p>
              <div className="flex flex-col gap-[10px]">
                <div className="pl-[15px] flex items-center gap-[6px]">
                  <img src="assets/home/icon11.svg" alt="" />
                  <p
                    data-aos="fade-right"
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[400] text-[16px] leading-[26px] text-[#212121]"
                  >
                    Each & every minute detail from
                  </p>
                </div>
                <div className="pl-[15px] flex items-center gap-[6px]">
                  <img src="assets/home/icon11.svg" alt="" />
                  <p
                    data-aos="fade-left"
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[400] text-[16px] leading-[26px] text-[#212121]"
                  >
                    fabric, design, measurements, trims
                  </p>
                </div>
                <div className="pl-[15px] flex items-center gap-[6px]">
                  <img src="assets/home/icon11.svg" alt="" />
                  <p
                    data-aos="fade-right"
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[400] text-[16px] leading-[26px] text-[#212121]"
                  >
                    printing, embroidery, washing etc.
                  </p>
                </div>
                <div className="pl-[15px] flex items-center gap-[6px]">
                  <img src="assets/home/icon11.svg" alt="" />
                  <p
                    data-aos="fade-left"
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[400] text-[16px] leading-[26px] text-[#212121]"
                  >
                    To packaging of finished garments
                  </p>
                </div>
              </div>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="pl-[17px] font-[400] text-[16px] leading-[26px] text-[#212121] pb-[58px]"
              >
                If everything is approved then we proceed further to develop PP
                samples for the final go-ahead
              </p>
              <button className="font-[DM Sans] font-[500] text-[20px] leading-[26.04px] py-[15px] px-[28px] bg-[#000000] text-[#FFFFFF] rounded-[30px] absolute left-[50%] translate-x-[-50%] bottom-[-28px] max-w-[226px] w-full">
                Sample Tech Pack
              </button>
            </div>
            <div className="relative bg-[#FFFFFF] border border-[#E9E9E9] rounded-[16px] px-[10px] pt-[18px]">
              <div
                style={{ boxShadow: "0px 7px 29px 0px #64646F33" }}
                className="h-[92px] bg-[#FFFFFF] w-[92px] py-[20px] px-[22px] rounded-full font-[DM Sans] font-[700] text-[40px] leading-[52.08px] text-[#94949E] absolute left-[50%] translate-x-[-50%] top-[-46px]"
              >
                #2
              </div>
              <div className="flex justify-end pb-[24px]">
                <button
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="bg-[#6D56FF] rounded-[4.62px] px-[14px] py-[1px] font-[600] text-[16.15px] leading-[30px] text-[#FFFFFF]"
                >
                  2 Weeks TAT
                </button>
              </div>
              <h6
                data-aos="fade-left"
                className="font-[DM Sans] pl-[17px] font-[700] text-[26px] leading-[33.85px] text-[#212121]"
              >
                Prototype & PP Samples
              </h6>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[14px] leading-[26px] text-[#94949E] pl-[17px] pb-[22px]"
              >
                Sample development & approvals
              </p>
              <div className="border-dashed border relative border-[#000000]">
                <img
                  src="assets/home/icon9.svg"
                  className="absolute left-[-20px] top-[50%] translate-y-[-50%]"
                  alt=""
                />
                <img
                  src="assets/home/icon10.svg"
                  className="absolute right-[-20px] top-[50%] translate-y-[-50%]"
                  alt=""
                />
              </div>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[16px] leading-[26px] text-[#212121] pl-[21px] pt-[15px] pb-[25px]"
              >
                After discussion on your tech packs, we develop a prototype of
                your clothing design.
              </p>
              <div className="flex flex-col gap-[10px]">
                <div className="pl-[15px] flex items-center gap-[6px]">
                  <img src="assets/home/icon11.svg" alt="" />
                  <p
                    data-aos="fade-right"
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[400] text-[16px] leading-[26px] text-[#212121]"
                  >
                    Check fitting, stitching quality & design
                  </p>
                </div>
                <div className="pl-[15px] flex items-center gap-[6px]">
                  <img src="assets/home/icon11.svg" alt="" />
                  <p
                    data-aos="fade-left"
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[400] text-[16px] leading-[26px] text-[#212121]"
                  >
                    check & confirm on prototype
                  </p>
                </div>
                <div className="pl-[15px] flex items-center gap-[6px]">
                  <img src="assets/home/icon11.svg" alt="" />
                  <p
                    data-aos="fade-right"
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[400] text-[16px] leading-[26px] text-[#212121]"
                  >
                    Approval for size grading
                  </p>
                </div>
                <div className="pl-[15px] flex items-center gap-[6px]">
                  <img src="assets/home/icon11.svg" alt="" />
                  <p
                    data-aos="fade-left"
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[400] text-[16px] leading-[26px] text-[#212121]"
                  >
                    re-development if incorrect
                  </p>
                </div>
              </div>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="pl-[17px] font-[400] text-[16px] leading-[26px] text-[#212121] pb-[58px]"
              >
                If everything is approved then we proceed further to develop PP
                samples for the final go-ahead
              </p>
            </div>
            <div className="relative bg-[#FFFFFF] border border-[#E9E9E9] rounded-[16px] px-[10px] pt-[18px]">
              <div
                style={{ boxShadow: "0px 7px 29px 0px #64646F33" }}
                className="h-[92px] bg-[#FFFFFF] w-[92px] py-[20px] px-[22px] rounded-full font-[DM Sans] font-[700] text-[40px] leading-[52.08px] text-[#212121] absolute left-[50%] translate-x-[-50%] top-[-46px]"
              >
                #3
              </div>
              <div className="flex justify-end pb-[24px]">
                <button
                  style={{ fontFamily: "Segoe UI, sans-serif" }}
                  className="bg-[#838CCE] rounded-[4.62px] px-[14px] py-[1px] font-[600] text-[16.15px] leading-[30px] text-[#FFFFFF]"
                >
                  4-6 Weeks TAT
                </button>
              </div>
              <h6
                data-aos="fade-left"
                className="font-[DM Sans] pl-[17px] font-[700] text-[26px] leading-[33.85px] text-[#212121]"
              >
                Finalisation & Dispatch
              </h6>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[14px] leading-[26px] text-[#94949E] pl-[17px] pb-[22px]"
              >
                Bulk production & logistics management
              </p>
              <div className="border-dashed border relative border-[#000000]">
                <img
                  src="assets/home/icon9.svg"
                  className="absolute left-[-20px] top-[50%] translate-y-[-50%]"
                  alt=""
                />
                <img
                  src="assets/home/icon10.svg"
                  className="absolute right-[-20px] top-[50%] translate-y-[-50%]"
                  alt=""
                />
              </div>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[16px] leading-[26px] text-[#212121] pl-[21px] pt-[15px] pb-[25px]"
              >
                Bulk production is the final step inside our clothing factory
                post pre-production samples approval.
              </p>
              <div className="flex flex-col gap-[10px]">
                <div className="pl-[15px] flex items-center gap-[6px]">
                  <img src="assets/home/icon11.svg" alt="" />
                  <p
                    data-aos="fade-right"
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[400] text-[16px] leading-[26px] text-[#212121]"
                  >
                    Order complexity defines actual TAT
                  </p>
                </div>
                <div className="pl-[15px] flex items-center gap-[6px]">
                  <img src="assets/home/icon11.svg" alt="" />
                  <p
                    data-aos="fade-left"
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[400] text-[16px] leading-[26px] text-[#212121]"
                  >
                    clothing products sealed for dispatched
                  </p>
                </div>
                <div className="pl-[15px] flex items-center gap-[6px]">
                  <img src="assets/home/icon11.svg" alt="" />
                  <p
                    data-aos="fade-right"
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[400] text-[16px] leading-[26px] text-[#212121]"
                  >
                    Door-to-door delivered via air or sea
                  </p>
                </div>
                <div className="pl-[15px] flex items-center gap-[6px]">
                  <img src="assets/home/icon11.svg" alt="" />
                  <p
                    data-aos="fade-left"
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[400] text-[16px] leading-[26px] text-[#212121]"
                  >
                    Happy ordering 
                  </p>
                </div>
              </div>
              <p
                data-aos="fade-right"
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="pl-[17px] font-[400] text-[16px] leading-[26px] text-[#212121] pb-[58px]"
              >
                If everything is approved then we proceed further to develop PP
                samples for the final go-ahead
              </p>
              <button className="font-[DM Sans] font-[500] text-[20px] leading-[26.04px] py-[15px] px-[28px] bg-[#7A29FF] text-[#FFFFFF] rounded-[30px] absolute left-[50%] translate-x-[-50%] bottom-[-28px] max-w-[226px] w-full">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#000000] overflow-hidden sm:px-[50px] sm:h-[898px] h-auto px-[20px] sm:pb-0 pb-[50px]">
        <div className="grid sm:grid-cols-2 grid-cols-1 items-center gap-[50px]">
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-[22px]">
            <Custom1 />
            <Custom2 />
            <Custom3 />
            {/* <div className="flex flex-col gap-[22px]">
              <div className="relative" data-aos="fade-up">
                <img
                  src="assets/item/item1.png"
                  className="w-full object-cover"
                  alt=""
                />
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
                  }}
                  className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
                >
                  <p
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
                  >
                    Stalls/Scarfs
                  </p>
                </div>
              </div>
              <div className="relative" data-aos="fade-down">
                <img
                  src="assets/item/item2.png"
                  className="w-full object-cover"
                  alt=""
                />
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
                  }}
                  className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
                >
                  <p
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
                  >
                    Masks
                  </p>
                </div>
              </div>
              <div className="relative" data-aos="fade-up">
                <img
                  src="assets/item/item3.png"
                  className="w-full object-cover"
                  alt=""
                />
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
                  }}
                  className="absolute bottom-0 w-full h-[76px]"
                >
                  <p
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
                  >
                    Stalls/Scarfs
                  </p>
                </div>
              </div>
            </div> */}
            {/* <div className="flex flex-col gap-[22px]">
              <div className="relative" data-aos="fade-down">
                <img
                  src="assets/item/item4.png"
                  className="w-full object-cover"
                  alt=""
                />
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
                  }}
                  className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
                >
                  <p
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
                  >
                    Pants/Plazzo
                  </p>
                </div>
              </div>
              <div className="relative" data-aos="fade-up">
                <img
                  src="assets/item/item5.png"
                  className="w-full object-cover"
                  alt=""
                />
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
                  }}
                  className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
                >
                  <p
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
                  >
                    Dresses/Maxi/Gowns
                  </p>
                </div>
              </div>
              <div className="relative" data-aos="fade-down">
                <img
                  src="assets/item/item6.png"
                  className="w-full object-cover"
                  alt=""
                />
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
                  }}
                  className="absolute bottom-0 w-full h-[76px]"
                >
                  <p
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
                  >
                    Stalls/Scarfs
                  </p>
                </div>
              </div>
            </div> */}
            {/* <div className="flex flex-col gap-[22px]">
              <div className="relative">
                <img
                  src="assets/item/item7.png"
                  className="w-full object-cover"
                  alt=""
                />
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
                  }}
                  className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
                >
                  <p
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
                  >
                    Kurti/Tunic
                  </p>
                </div>
              </div>
              <div className="relative">
                <img
                  src="assets/item/item8.png"
                  className="w-full object-cover"
                  alt=""
                />
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
                  }}
                  className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
                >
                  <p
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
                  >
                    Tops/T-shirts/Blouses
                  </p>
                </div>
              </div>
              <div className="relative">
                <img
                  src="assets/item/item9.png"
                  className="w-full object-cover"
                  alt=""
                />
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
                  }}
                  className="absolute bottom-0 w-full h-[76px]"
                >
                  <p
                    style={{ fontFamily: "Segoe UI, sans-serif" }}
                    className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
                  >
                    Stalls/Scarfs
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          <div className="overflow-hidden">
            <p
              data-aos="fade-up"
              className="font-[DM Sans] font-[500] text-[20px] leading-[26.04px] text-[#7A29FF] pb-[2px]"
            >
              We have worked in the designer categories for women /men and kid :
            </p>
            <h6
              data-aos="fade-left"
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[700] sm:text-[48px] text-[30px] sm:leading-[63.84px] leading-[36px] text-[#FFFFFF] pb-[4px]"
            >
              Custom fabric, colour & styles.
            </h6>
            <p
              data-aos="fade-right"
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[400] text-[20px] leading-[24px] text-[#FFFFFF]"
            >
              Our collaborative approach, combined with our passion for
              innovation, sets us apart as a trusted partner for apparel
              manufacturing.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#FFFFFF] overflow-hidden sm:pt-[94px] pt-[40px]">
        <div className="flex sm:flex-row flex-col sm:justify-end justify-center relative sm:gap-0 gap-[40px]">
          <img src="assets/home/end-picture.jpg" data-aos="fade-up" alt="" />
          <div className="absolute sm:block overflow-hidden hidden bg-[#7A29FF] rounded-tr-[114px] border-[8px] border-[#FFFFFF] pt-[53px] max-w-[769px] w-full bottom-[39px] left-[80px] px-[50px]">
            <img
              src="assets/item/logo.svg"
              className="absolute right-[49px] bottom-[33px]"
              alt=""
            />
            <p
              data-aos="fade-right"
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[700] text-[16px] leading-[21.28px] text-[#FFFFFFCC] pb-[10px]"
            >
              LOOKING FOR THE BEST CUSTOM CLOTHING MANUFACTURER IN INDIA?
            </p>
            <h6
              data-aos="fade-left"
              className="font-[DM Sans] font-[700] sm:text-[40px] text-[24px] sm:leading-[38px] leading-[24px] text-[#FFFFFF] pb-[72px]"
            >
              We are a custom made clothing manufacturer & fabrics export house
              from Ludhiana, India
            </h6>
          </div>
          <div className="relative block sm:hidden bg-[#7A29FF] border-[8px] border-[#FFFFFF] pt-[53px] max-w-[769px] w-full sm:px-[50px] px-[20px]">
            <img
              src="assets/item/logo.svg"
              className="absolute right-[49px] bottom-[33px]"
              alt=""
            />
            <p
              style={{ fontFamily: "Segoe UI, sans-serif" }}
              className="font-[700] text-[16px] leading-[21.28px] text-[#FFFFFFCC] pb-[10px]"
            >
              LOOKING FOR THE BEST CUSTOM CLOTHING MANUFACTURER IN INDIA?
            </p>
            <h6 className="font-[DM Sans] font-[700] text-[40px] leading-[38px] text-[#FFFFFF] pb-[72px]">
              We are a custom made clothing manufacturer & fabrics export house
              from Ludhiana, India
            </h6>
          </div>
        </div>
      </div>
      <div className="bg-[#fbf0f1] sm:px-[100px] px-[20px] py-[50px]">
        <div className="container">
          <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-[100px] gap-[40px]">
            <div>
              <p className="border-[2px] flex items-center font-[500] text-[14px] gap-[8px] rounded-[12px]  py-[4px] px-[8px]">
                Trusted overseas clothing manufacturer
              </p>
              <h6 className="font-[600] text-[#111013] text-[46px] leading-[50px] pt-[16px]">
                <span className="text-[#635d6f]">Turning your clothing </span>
                <br /> idea into reality.{" "}
              </h6>
              <p className="mt-[16px]">
                Just fill the contact form with your details along with your{" "}
                <br />
                clothing requirements and we’ll get back to you{" "}
                <b>within 24hrs</b>
              </p>
            </div>
            <div>
              <h6 className="text-[67px] font-[DM Sans] leading-[70px] font-[600] text-[#111013]">
                24X7{" "}
              </h6>
              <div className="bg-[#000] mt-[16px] w-full h-[1px]"></div>
              <ul className="flex flex-col pt-[20px] gap-[8px]">
                <li className="flex gap-[10px]">
                  <img
                    src="/assets/check.svg"
                    className="h-[16px] w-[16px]"
                    alt=""
                  />
                  Indian clothing manufacturer
                </li>
                <li className="flex gap-[10px]">
                  <img
                    src="/assets/check.svg"
                    className="h-[16px] w-[16px]"
                    alt=""
                  />
                  Custom make-to-order
                </li>
                <li className="flex gap-[10px]">
                  <img
                    src="/assets/check.svg"
                    className="h-[16px] w-[16px]"
                    alt=""
                  />
                  Prototype, bulk & logistics
                </li>
              </ul>
              <button className="bg-[#000000] text-[#FFFFFF] px-[18px] py-[12px] mt-[10px] rounded-[6px] font-[DM Sans] text-[16px] font-[700] leading-[24px]">
                Contact NGA
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
