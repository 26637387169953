import React from "react";
import Slider from "react-slick";

function Custom3() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <div className="relative">
            <img
              src="/assets/women/img1.JPG"
              className="w-full object-cover rounded-[18px]"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Kurti/Tunic
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="/assets/women/img2.JPG"
              className="w-full object-cover rounded-[18px]"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Tops/T-shirts/Blouses
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="/assets/women/img3.JPG"
              className="w-full object-cover rounded-[18px]"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Stalls/Scarfs
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="/assets/women/img4.JPG"
              className="w-full object-cover rounded-[18px]"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Kurti/Tunic
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="/assets/women/img5.JPG"
              className="w-full object-cover rounded-[18px]"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px] rounded-b-[18px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Tops/T-shirts/Blouses
              </p> */}
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <img
              src="/assets/women/img6.PNG"
              className="w-full object-cover rounded-[18px]"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #161519 100%)",
              }}
              className="absolute bottom-0 w-full h-[76px]"
            >
              {/* <p
                style={{ fontFamily: "Segoe UI, sans-serif" }}
                className="font-[600] text-[18px] leading-[26px] pt-[36px] px-[16px] text-[#FFFFFF]"
              >
                Stalls/Scarfs
              </p> */}
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
}

export default Custom3;
